
const initialState = [];

export default (state = initialState, action = null) => {
  switch (action.type) {
    case 'ADD_STUDY_DOWNLOAD':
      return !state.find((item) => item.uuid === action.download.uuid) ? [...state, action.download] : state;
    case 'REMOVE_STUDY_DOWNLOAD':
      return state.filter((download) => !(download.uuid === action.uuid));
    case 'RESET_STUDY_DOWNLOAD':
      return [];
    default:
      return state;
  }
};
