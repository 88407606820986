import React from 'react';

import { ApolloProvider } from 'react-apollo';
import { KeycloakProvider, useKeycloak } from 'react-keycloak';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-US';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { getTheme } from './src/themes/materialUITheme';
import { getClient } from './src/apollo/client';
import { getKeycloakInstance } from './src/keycloak/index';
import store from './src/store';
import { main } from './src/themes/main';

const Locales = { es: esLocale, en: enLocale };

const Base = ({ element }) => {
  const locale = Locales[i18n.language];
  const currentHospital = useSelector((state) => state.hospital);
  const theme = getTheme(currentHospital);

  try {
    useTranslation();

    return (
      <ThemeProvider theme={main}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            {element}
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    );
  } catch (e) {
    console.warn(e && e.message);
    setTimeout(() => { window.location.reload(); }, 2500);
    return <div>Loading, please wait...</div>;
  }
};

const ApolloContext = ({ element }) => {
  const [keycloak] = useKeycloak();
  const apolloClient = getClient(keycloak.token);

  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <Base element={element} />
      </Provider>
    </ApolloProvider>
  );
};

const KeycloakContext = ({ element }) => {
  const keycloakInstance = getKeycloakInstance();

  const keycloakProviderInitConfig = {
    onLoad: 'check-sso',
    checkLoginIframe: false,
    promiseType: 'native',
  };

  return (
    <KeycloakProvider
      keycloak={keycloakInstance}
      initConfig={keycloakProviderInitConfig}
    >
      <ApolloContext element={element} />
    </KeycloakProvider>
  );
};

export const wrapRootElement = ({ element }) => (
  <KeycloakContext element={element} />
);
