// eslint-disable-next-line import/no-extraneous-dependencies
import { combineReducers } from 'redux';
import UserInterfaceReducer from './UserInterfaceReducer';
import HospitalReducer from './HospitalReducer';
import ProviderReducer from './ProviderReducer';
import UploadingStudiesReducer from './UploadingStudiesReducer';
import UploadingStudiesConfirmationReducer from './UploadingStudiesConfirmationReducer';
import DownloadingStudiesReducer from './DownloadingStudiesReducer';

export default combineReducers({
  userInterface: UserInterfaceReducer,
  hospital: HospitalReducer,
  provider: ProviderReducer,
  uploadingStudies: UploadingStudiesReducer,
  uploadingStudiesConfirmation: UploadingStudiesConfirmationReducer,
  downloadingStudies: DownloadingStudiesReducer,
});
