
const initialState = [];

const updateStudyUploadFileIndex = (state, action) => (
  state.map((study) => {
    if (study.id === action.study.id) {
      return {
        ...study,
        nextFileIndex: study.nextFileIndex + 1,
      };
    }
    return study;
  })
);

const updateStudyUploadFilesCompleted = (state, action) => {
  let newState = state.filter((study) => (
    // Remove study if all files have been uploaded
    !(study.id === action.study.id && study.filesCompleted + 1 === study.files.length)
  ));

  newState = newState.map((study) => {
    // Increase study files completed if there are files pending to be uploaded
    if (study.id === action.study.id) {
      return {
        ...study,
        filesCompleted: study.filesCompleted + 1,
      };
    }
    return study;
  });

  return newState;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_UPLOADING_STUDY':
      return [...state, action.study];
    case 'UPDATE_STUDY_UPLOAD_FILE_INDEX':
      return updateStudyUploadFileIndex(state, action);
    case 'UPDATE_STUDY_UPLOAD_FILES_COMPLETED':
      return updateStudyUploadFilesCompleted(state, action);
    default:
      return state;
  }
};
