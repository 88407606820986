// @flow

import 'cross-fetch/polyfill';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';

const cache = new InMemoryCache({
  dataIdFromObject: (object) => object.uuid || null,
});

export const getClient = (token) => new ApolloClient({
  cache,
  link: createUploadLink({
    uri: process.env.GATSBY_CLOUD_API_URL,
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  }),
});
