
const initialState = {
  visibleSidebar: false,
  fixSidebar: true,
  visibleWorkspaces: false,
  showModal: false,
  showViewer: false,
  releaseNotes: { display: true, type: 'latest' },
  viewerImages: [],
  user: null,
  workspacePage: '/dashboard',
  licenseWarning: false,
  studiesView: 'grid',
  importStudyQueue: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_VISIBILITY_FILTER':
      return { ...state, ...action.filter };
    case 'TOGGLE_MODAL':
      return { ...state, ...action.modalState };
    case 'RELEASE_NOTES':
      return { ...state, ...action.releaseNotes };
    case 'TOGGLE_VIEWER':
      return { ...state, ...action.showViewer };
    case 'SET_VIEWER_IMAGES':
      return { ...state, ...action.viewerImages };
    case 'SET_USER':
      return { ...state, ...action.user };
    case 'SET_WORKSPACE_PAGE':
      return { ...state, ...action.workspacePage };
    case 'SET_LICENSE':
      return { ...state, ...action.licenseWarning };
    case 'SET_STUDY_LIST_VIEW':
      return { ...state, ...action.studiesView };
    case 'IMPORT_STUDY_QUEUE':
      return { ...state, ...action.importStudyQueue };
    default:
      return state;
  }
};
