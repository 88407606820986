
const initialState = [];

export default (state = initialState, action = null) => {
  switch (action.type) {
    case 'ADD_STUDY_UPLOAD_FILES_TO_CONFIRM':
      return [...state, action.study];
    case 'REMOVE_STUDY_UPLOAD_FILES_TO_CONFIRM':
      return state.filter((study) => !(study.uuid === action.study.uuid));
    case 'RESET_STUDY_UPLOAD_FILES_TO_CONFIRM':
      return [];
    default:
      return state;
  }
};
